import { render, staticRenderFns } from "./indexDelegadosUcp.vue?vue&type=template&id=d852acd6"
import script from "./indexDelegadosUcp.vue?vue&type=script&lang=js"
export * from "./indexDelegadosUcp.vue?vue&type=script&lang=js"
import style0 from "./indexDelegadosUcp.vue?vue&type=style&index=0&id=d852acd6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports